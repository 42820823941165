.header{
  box-sizing: border-box;
  font-weight: bold;
  padding: 1em 3em 3em;
  text-align: center;
  width: 100%;

  a, a:visited{
    color: #fff;
    font-size: 3.5em;
    margin: 0;
    padding: 0;
    text-align: center;
    text-decoration: none;
  }

  h2{
    color: #eeffff;
    font-size: 1.8em;
    margin: 0;
    padding: 0 1.7em 1.1em;
    text-align: center;
  }
}
