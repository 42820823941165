@mixin no-select{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin select{
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@mixin flex-justify {
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin elevation($elevation){
  box-shadow: 0 $elevation + px $elevation * 2 + px rgba(0,0,0,0.12), 0 $elevation + px $elevation * 2 + px rgba(0,0,0,0.24);
}

@mixin view-shadow($color: rgba(0, 0, 0, .3)){
  -moz-box-shadow: 0 0 30px 5px $color inset;
  -webkit-box-shadow: 0 0 30px 5px $color inset;
  -o-box-shadow: 0 0 30px 5px $color inset;
  box-shadow: 0 0 30px 5px $color inset;
}

@mixin bottom-round-corners($radius){
  -webkit-border-bottom-right-radius: $radius + px;
  border-bottom-right-radius: $radius + px;
  -webkit-border-bottom-left-radius: $radius + px;
  border-bottom-left-radius: $radius + px;
}

@mixin top-round-corners($radius){
  -webkit-border-top-right-radius: $radius + px;
  border-top-right-radius: $radius + px;
  -webkit-border-top-left-radius: $radius + px;
  border-top-left-radius: $radius + px;
}

@mixin round-corners($radius){
  @include bottom-round-corners($radius);
  @include top-round-corners($radius);
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin keep-together {
  page-break-inside: avoid;
}

@mixin vertical-gradient($color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(-90deg, $color-stops);
  background: linear-gradient(180deg, $color-stops);
}
