@import "../common.scss";

.nav_component{
  nav {
    border: solid thin black;
    @include vertical-gradient(#777 25%, #444 100%);
    @include border-radius(5px);
    font-size: 1.4em;
    margin: auto auto 2em;
    padding: 0 4px;
    position: relative;
    width: 60%;

    ul{
      @include flex-justify;
      margin: 0 1em;
      padding: 0;

      a{
        color: #aaa;
        font-weight: bold;
        padding: 0 1em;
        text-decoration: none;
      }

      a.active, a:hover{
        color: #fff;
      }

      li{
        display: inline-block;
        list-style: none;
        line-height: 3em;
      }
    }
  }
}
